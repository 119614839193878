
import { defineComponent, reactive, ref, Ref, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getCaptcha, login, getCode, verifyCaptcha } from '@/api/auth';
import { getUser } from '@/shared/userinfo';

export default defineComponent({
  name: 'LoginForm',
  setup() {
    const instance = getCurrentInstance();
    const formRef: Ref<any> = ref(null);
    const router = useRouter();
    const route = useRoute();
    const loginInfo = reactive({
      username: '',
      password: '',
      captchaCode: '',
    });
    const captchaUrl = ref('');

    const formRules = {
      username: [{ required: true, message: '帐号不能为空!', trigger: 'blur' }],
      password: [
        { required: true, message: '密码不能为空!', trigger: 'blur' },
        { min: 8, max: 20, message: '密码长度为 8 到 20 个字符', trigger: 'blur' },
      ],
      captchaCode: [{ required: true, message: '验证码不能为空!', trigger: 'blur' }],
    };

    const getCaptchaUrl = async () => {
      const { data } = await getCaptcha();
      captchaUrl.value = data;
    };
    getCaptchaUrl();

    const goForgetPassword = () => {
      router.push('/forget-password');
    };

    const isPassed: Ref<boolean> = ref(false);

    const onInputCaptchaCode = async (value: string) => {
      if (!value) {
        isPassed.value = false;
        return;
      }
      try {
        const { data } = await verifyCaptcha({ captchaCode: value });
        if (data) {
          isPassed.value = true;
        } else {
          isPassed.value = false;
        }
      } catch (error) {
        isPassed.value = false;
      }
    };

    const loading: Ref<boolean> = ref(false);

    const onLogin = async () => {
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          try {
            loading.value = true;
            const { data } = await getCode({
              captchaCode: loginInfo.captchaCode,
            });
            const { code } = await login({
              account: loginInfo.username,
              loginVerifyCode: data.loginVerifyCode,
              secret: `${loginInfo.password}.${data.code}`,
            });
            if (code === 0) {
              await getUser();
              loading.value = false;
              if (route.query.redirect) {
                router.push(route.query.redirect as string);
              } else {
                router.push('/');
              }
            } else {
              loading.value = false;
              loginInfo.captchaCode = '';
              isPassed.value = false;
              getCaptchaUrl();
              let msg = '';
              switch (code) {
                case 1105001:
                  msg = '验证码错误！';
                  break;
                case 1118000:
                  msg = '该账号已处于禁用状态，请联系管理员申请权限!';
                  break;
                default:
                  msg = '帐号或密码错误，请重新输入！';
              }
              (instance as any).proxy.$message({
                type: 'error',
                message: msg,
              });
            }
          } catch {
            loading.value = false;
            loginInfo.captchaCode = '';
            isPassed.value = false;
            getCaptchaUrl();
          }
        }
      });
    };
    return {
      formRef,
      loading,
      loginInfo,
      captchaUrl,
      formRules,
      getCaptchaUrl,
      goForgetPassword,
      isPassed,
      onInputCaptchaCode,
      onLogin,
    };
  },
});
