import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_password_form = _resolveComponent("password-form")!
  const _component_reset_password = _resolveComponent("reset-password", true)!

  return (_openBlock(), _createBlock(_component_reset_password, null, {
    default: _withCtx(() => [
      _createVNode(_component_password_form, {
        email: _ctx.email,
        userId: _ctx.userId,
        code: _ctx.code
      }, null, 8, ["email", "userId", "code"])
    ]),
    _: 1
  }))
}