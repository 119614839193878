
import { defineComponent } from 'vue';
import LoginForm from './components/LoginForm.vue';
export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
  },
  setup() {
    const backToHead = () => {
      window.location.href = 'https://citybase.qq.com';
      // window.location.reload();
    };
    return {
      backToHead,
    };
  },
});
