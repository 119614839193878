
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { PasswordRules } from '@/utils/validate';
import { resetPassWord } from '@/api/company/users';
import useMsg from '../useMsg';
export default defineComponent({
  name: 'Password',
  props: {
    email: {
      type: String as PropType<string>,
      default: '',
    },
    userId: {
      type: Number as PropType<number>,
      default: 0,
    },
    code: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const formData = reactive({
      password: '',
      confirmationPassword: '',
      email: computed(() => props.email),
    });
    const form: any = ref(null);

    // 获取组件实例
    const { msgTips, goLoginPages } = useMsg();
    const validatePassword = (rule: any, value: string, callback: Function) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (formData.confirmationPassword !== '') {
          // eslint-disable-next-line no-unused-expressions
          form.value?.validateField('confirmationPassword');
        }
        callback();
      }
    };
    const validateConfirmationPassword = (rule: any, value: string, callback: Function) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== formData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const rules = {
      password: [...PasswordRules, { validator: validatePassword, trigger: 'blur' }],
      confirmationPassword: [
        { required: true, message: '请再次输入新的密码', trigger: 'blur' },
        { validator: validateConfirmationPassword, trigger: 'blur' },
      ],
    };

    const submit = () => {
      // disable submit
      // eslint-disable-next-line no-unused-expressions
      form.value?.validate(async (valid: boolean) => {
        if (valid) {
          const { code } = await resetPassWord({
            resetCode: props.code,
            newPassword: formData.password,
            userId: props.userId,
          });
          if (code === 0) {
            msgTips('success', '密码重置成功');
          } else {
            msgTips('error', '密码重置失败');
          }
          goLoginPages();
        } else {
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      form,
      submit,
    };
  },
});
